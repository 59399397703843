@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-briefcase: "\EA01"
$icon-check-circle: "\EA02"
$icon-file-text-1: "\EA03"
$icon-file-text: "\EA04"
$icon-logo: "\EA05"
$icon-truck: "\EA06"
$icon-user: "\EA07"
$icon-users: "\EA08"
$icon-arrow-left-circle: "\EA09"
$icon-webcase_purple_black: "\EA0A"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-briefcase:before
	content: $icon-briefcase
.icon-check-circle:before
	content: $icon-check-circle
.icon-file-text-1:before
	content: $icon-file-text-1
.icon-file-text:before
	content: $icon-file-text
.icon-logo:before
	content: $icon-logo
.icon-truck:before
	content: $icon-truck
.icon-user:before
	content: $icon-user
.icon-users:before
	content: $icon-users
.icon-arrow-left-circle:before
	content: $icon-arrow-left-circle
.icon-webcase_purple_black:before
	content: $icon-webcase_purple_black
